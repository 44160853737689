.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spin::before {
    animation: spinner 1.5s infinite linear; /* Smooth, consistent rotation */
    border: solid 4px #cfd0d1; /* Lighter border for a modern look */
    border-top-color: #2B3D45; /* Distinguished color for visibility */
    border-radius: 50%;
    content: "";
    height: 40px;
    width: 40px;
    position: absolute;
    transform: translate3d(-50%, -50%, 0); /* Keep the original positioning logic */
    will-change: transform;
}
