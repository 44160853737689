/*Global Navbar styles*/
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .logo {
    display: flex;
  }
  
  .navLinks {
    display: flex;
  }
  
  .logInButton {
    border: none;
    background-color: transparent;
    width: 144px;
    height: 44px;
    font-size: 16px;
    font-weight: 600;
  }
  
  .logInButtonText {
    /* color: white; */
    font-size: 14px;
    /* font-weight: normal; */
  }
  
  .freeTrialButtonText {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
  
  .CreateAccountHeroImageContainer {
    height: 100vh;
    width: 100vw;
    border: 1px solid black;
    background-color: grey;
  }